import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function MeetVirginia() {
  return (
    <Layout>
      <Helmet title="Meet Virginia | Train" />

      <h1>Meet Virginia</h1>

      <h2>Train</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>She doesn't own a dress, her hair is always a mess</p>
          <p>If you catch her stealin', she won't confess</p>
          <p>She's beautiful, she smokes a pack a day, wait that's me, but anyway</p>
          <p>She doesn't care a thing about that, hey</p>
          <p>She thinks I'm beautiful. Meet Virginia</p>
        </Verse>
        <Verse>
          <p>She never compromises, loves babies and surprises</p>
          <p>Wears hi-heels when she exercises</p>
          <p>Ain't that beautiful</p>
          <p>Meet Virginia</p>
        </Verse>
        <Chorus>
          <p>Well she wants to be the Queen</p>
          <p>Then she thinks about her scene</p>
          <p>Pulls her hair back as she screams</p>
          <p>I don't really wanna be the queen</p>
        </Chorus>
        <Verse>
          <p>Her daddy wrestles alligators, Mama works on carburetors</p>
          <p>Her brother is a fine mediator for the President</p>
          <p>Well here she is again on the phone</p>
          <p>Just like me hates to be alone</p>
          <p>We just like to sit at home, and rip on the President</p>
          <p>Meet Virginia</p>
        </Verse>
        <Chorus>
          <p>Well she wants to live her life</p>
          <p>Then she thinks about her life</p>
          <p>Pulls her hair back as she screams</p>
          <p>I don't really wanna live this life</p>
        </Chorus>
        <Bridge>
          <p>CHORDS</p>
        </Bridge>
        <Verse>
          <p>She only drinks coffee at midnight, when the moment is not</p>
          <p>Right, her timing is quite unusual</p>
          <p>You see her confidence is tragic, but her intuition magic</p>
          <p>And the shape of her body unusual</p>
          <p>Meet Virgina I can't wait to</p>
          <p>Meet Virginia, yeah e yeah hey hey hey</p>
        </Verse>
        <Chorus>
          <p>Well she wants to be the queen and</p>
          <p>Then she thinks about her scene</p>
          <p>Well she wants to live her life</p>
          <p>Then she thinks about her life</p>
          <p>Pulls her hair back as she screams</p>
          <p>I don't really wanna be the queen</p>
          <p>I, I don't really wanna be the queen</p>
          <p>I, I don't really wanna be the queen</p>
          <p>I, I don't really wanna live this</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
